<template>
  <div class="design-layout">
    <draggable v-model="myArray" animation="300" style="width: 320px; height: 568px; border: 1px solid #000; margin: 100px auto">
      <transition-group>
        <div v-for="element in myArray" :key="element.id" class="item">
          {{element.name}}
        </div>
      </transition-group>
    </draggable>
    sdfsdfsdf
  </div>
</template>

<script>

import draggable from 'vuedraggable'

export default {
  components: {
    draggable
  },
  data(){
    return {
      myArray: [{
        id:1,
        name:'A'
      },{
        id:2,
        name:'B'
      },{
        id:3,
        name:'C'
      },{
        id:4,
        name:'D'
      },{
        id:5,
        name:'E'
      }]
    }
  },
  computed: {

  },
  methods: {

  },
  watch: {

  },
  created () {


  }
}
</script>
<style scoped>
.item{
  width: 100%; height: 50px ; border: 1px solid #ccc; margin: 10px auto
}
</style>
