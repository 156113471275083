<template>
  <div class="mainwhite">
    <div class="mainwhite-tiop">
      <el-form
          :inline="true"
          ref="formData"
          :model="formData"
          class="demo-form-inline"
      >
        <el-form-item
            prop="start_settle_mark"
        >
          <el-cascader placeholder="期别"
              v-model="formData.start_settle_mark"
              :options="start_settle_options"
              clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-input
              placeholder="请输入内容"
              v-model="formData.keywords"
              class="input-with-select"
          >
            <el-select
                style="width: 120px"
                v-model="formData.searchField"
                slot="prepend"
                placeholder="请选择"
            >
              <el-option label="会员编号" value="1">会员编号</el-option>
<!--              <el-option label="手机号" value="2">手机号</el-option>-->
<!--              <el-option label="身份证号" value="3">身份证号</el-option>-->
<!--              <el-option label="姓名" value="4">姓名</el-option>-->
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch('formData')"
          >查询
          </el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table
        ref="dataTable"
        v-loading="loading"
        :data="tableData"
        border
        stripe
        style="width: 100%; margin-top: 20px"
        @selection-change="handleSelectionChange"
    >
      <el-table-column
          prop="id"
          label="#ID"
          width="100">
      </el-table-column>
      <el-table-column
          prop="name"
          label="会员信息" width="250" >
        <template slot-scope="scope">
          <span v-if="scope.row.member.member_level === '1'" v-html="
          '真实姓名：'+scope.row.member.full_name+'<br />'+
          '手机号码：'+scope.row.member.mobile
"></span>
          <span v-if="scope.row.member.member_level === '3'" v-html="
          '会员编号：'+scope.row.member.uname+'<br />'+
          '真实姓名：'+scope.row.member.full_name+'<br />'+
          '手机号码：'+scope.row.member.mobile
"></span>
        </template>
      </el-table-column>
      <el-table-column
          prop="name"
          label="期别"
          >
      </el-table-column>
      <el-table-column
          prop="current_total_bonus"
          label="收入" >
      </el-table-column>
      <el-table-column
          prop="current_min_repeat_consumption"
          label="当期最低重复消费额度">
      </el-table-column>
      <el-table-column
          prop="total_min_repeat_consumption"
          label="最低重复消额度累计">
      </el-table-column>
      <el-table-column
          prop="total_finish_repeat_consumption"
          label="完成重复消费额度累计">
      </el-table-column>
      <el-table-column
        prop="total_undone_repeat_consumption"
        label="未完成重复消费额度">
      </el-table-column>
      <el-table-column
        prop="member_account4_balance"
        label="扣款池">
      </el-table-column>
      <el-table-column
        prop="repeat_release_bonus"
        label="复消返还">
      </el-table-column>
      <el-table-column
        prop="repeat_add_bonus"
        label="复消扣款">
      </el-table-column>
      <el-table-column
        prop="paid_bonus"
        label="实发">
      </el-table-column>
<!--      <el-table-column-->
<!--          fixed="right"-->
<!--          label="操作"-->
<!--          align="center"-->
<!--          header-align="center"-->
<!--      >-->
<!--        <template slot-scope="scope">-->
<!--          <el-button-->
<!--              style="margin: 2px 0"-->
<!--              @click="editKeepPv(scope.row)"-->
<!--              type="primary"-->
<!--              plain-->
<!--              size="small"-->
<!--              v-if="myfun.checkAuthRule('adminSettleEditMemberKeepPv') && scope.row.edit === '1'"-->
<!--          >编辑</el-button>-->
<!--          <span v-else>-</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
    <div class="block">
      <el-pagination
          style="margin-top: 1rem"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="formData.page"
          :page-sizes="pageSizs"
          :page-size="formData.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="formData.total"
      >
      </el-pagination>
    </div>


    <!-- Form -->

    <el-dialog
        title="保留业绩调整"
        :visible.sync="editKeepPvDialogFormVisible"
        v-loading="loading"
    >
      <el-form
          class="huiyuanxinxi"
          :model="editKeepPvFormData"
          :rules="checkEditKeepPvFormRules"
          ref="editKeepPvFormData"
      >
        <el-form-item
            label="原始保留积分"
            :label-width="formLabelWidth"
            prop="original_region_pv_balance"
        >
          <el-input
              v-model="editKeepPvFormData.original_region_pv_balance"
              autocomplete="off" disabled="disabled"
          ></el-input>
        </el-form-item>
        <el-form-item
            label="当前保留积分"
            :label-width="formLabelWidth"
            prop="region_pv_balance"
        >
          <el-input
              v-model="editKeepPvFormData.region_pv_balance"
              autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editKeepPvDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="doEditKeepPv('editKeepPvFormData')"
        >确 定</el-button
        >
      </div>
    </el-dialog>

  </div>
</template>

<script>
  import { settleData } from "@/util/settleData";
  export default {
    name: 'MemberKeepPvSearch',
    components: {},
    data() {
      return {
        showLog: false,
        formData: {
          page: 1,
          pagesize: 10,
          total: 0,
          keywords: '',
          searchField: '1',
          settleId: '',
          netType: '0',
          layer: '',
          start_settle_mark: "",
          end_settle_mark: "",
        },
        tableData: [],
        bonusLogData:[],
        loading: false,
        formLabelWidth: "120px",
        start_settle_options: settleData.start,
        end_settle_options: settleData.end,
        editKeepPvDialogFormVisible:false,
        editKeepPvFormData: {
          id: 0,
          original_region_pv_balance: 0,
          region_pv_balance: 0
        },
        checkEditKeepPvFormRules:{}
      };
    },
    methods: {
      getList(page = 0) {
        this.tableData = [];
        if (page > 0) {
          this.formData.page = page;
        }
        this.loading = true;
        this.myfun.request('adminSettleGetMemberRepeatPvList.api', this.formData, 'post').then((result) => {
          if (result.code === '1') {
            this.tableData = result.data.list;
          }
          this.formData.total = parseInt(result.data.total);
          this.loading = false;
        });
      },
      handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
        this.formData.pagesize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
        this.formData.page = val;
        this.getList();
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      handleClose(tag) {
        if (this.multipleSelection.length > 1) {
          this.$refs.dataTable.toggleRowSelection(tag, false);
        } else {
          this.$message.warning("请至少要保留一个操作项");
        }
      },
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //验证通过执行请求
            this.loading = true;
            let apiName =
              this.editNetformData.type === 1
                ? "adminMemberEditMemberRecommend.api"
                : "adminMemberEditMemberParent.api";
            this.myfun.request(apiName, this.editNetformData)
              .then((result) => {
                if (result.code === "1") {
                  this.getList();
                  this.editNetdialogFormVisible = false;
                }
                this.loading = false;
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      onSearch() {
        this.getList(1);
      },
      editKeepPv(row){
        this.editKeepPvFormData.id = row.id;
        this.editKeepPvFormData.original_region_pv_balance = row.original_region_pv_balance;
        this.editKeepPvFormData.region_pv_balance = row.region_pv_balance;
        this.editKeepPvDialogFormVisible = true;
      },
      doEditKeepPv(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //验证通过执行请求
            this.loading = true;
            this.myfun.request(
              "adminSettleEditMemberKeepPv.api",
              this.editKeepPvFormData,
              "post"
            )
              .then((result) => {
                if (result.code === "1") {
                  this.getList();
                }

                this.loading = false;
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },
    created() {
      this.getList(1);
    },
    mounted() {
    },
    computed: {
      pageSizs() {
        return this.$store.state.pageSizes;
      },
    },
  };
</script>

<style>
  .huiyuanxinxi .el-form-item {
    margin: 0 0 15px 0;
  }

  .switshsdf {
    display: flex;
    flex-direction: column;
  }

  .mainwhite {
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
  }

  .mainwhite-tiop {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #f5f5f5;
    justify-content: flex-end;
  }

  .el-form-item {
    margin-bottom: 0;
  }
</style>
