<template>
  <div class="mainwhite">
    <div class="mainwhite-tiop">
      <el-form :inline="true" ref="formData" :model="formData">
        <el-form-item>
          <el-select
            style="width: 220px"
            v-model="formData.country_id"
            placeholder="请选择国家/地区"
            @change="changeCountry"
          >
            <el-option
              v-for="item in countryList"
              :key="item.id"
              :label="item.name + '-' + item.name_en"
              :value="item.id"
            >
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.name_en
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            prop="start_settle_mark"
        >
          <el-cascader placeholder="起始期别" style="width: 180px"
                       v-model="formData.start_settle_mark"
                       :options="start_settle_options"
                       clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item
            prop="end_settle_mark"
        >
          <el-cascader placeholder="结束期别" style="width: 180px"
                       v-model="formData.end_settle_mark"
                       :options="start_settle_options"
                       clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-select
            style="width: 130px"
            v-model="formData.searchType"
            placeholder="请选择"
          >
            <el-option label="状态类型" value="0">状态类型</el-option>
            <el-option label="系统设置" value="1">系统设置</el-option>
            <el-option label="手动设置" value="2">手动设置</el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            style="width: 130px"
            v-model="formData.reach_agent_level_id"
            placeholder="达成成就"
          >
            <el-option label="达成成就" value="-1"></el-option>
            <el-option label="暂无奖衔" value="0"></el-option>
            <el-option
              :label="item.agent_name"
              :value="item.id"
              v-for="(item, index) in agentLevel_rs"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            style="width: 130px"
            v-model="formData.qualified_agent_level_id"
            placeholder="资格成就"
          >
            <el-option label="资格成就" value="-1"></el-option>
            <el-option label="暂无奖衔" value="0"></el-option>
            <el-option
              :label="item.agent_name"
              :value="item.id"
              v-for="(item, index) in agentLevel_rs"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            style="width: 130px"
            v-model="formData.highest_agent_level_id"
            placeholder="最高成就"
          >
            <el-option label="最高成就" value="-1"></el-option>
            <el-option label="暂无奖衔" value="0"></el-option>
            <el-option
              :label="item.agent_name"
              :value="item.id"
              v-for="(item, index) in agentLevel_rs"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="请输入内容"
            v-model="formData.keywords"
            class="input-with-select"
          >
            <el-select
              style="width: 150px"
              v-model="formData.searchField"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="会员编号" value="1">会员编号</el-option>
              <el-option label="手机号" value="2">手机号</el-option>
              <el-option label="身份证号" value="3"
                >身份证号</el-option
              >
              <el-option label="真实姓名" value="4">真实姓名</el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch('formData')"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-button
      @click="statusManage()"
      style="width: 100px; margin-top: 20px"
      v-if="myfun.checkAuthRule('adminMemberBitchAgentLog')"
      :disabled="multipleSelection.length < 1"
    >
      批量维护
    </el-button>
    <el-table
      ref="dataTable"
      v-loading="loading"
      :data="tableData"
      stripe
      border
      style="width: 100%; margin-top: 20px"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        align="center"
        header-align="center"
        width="40"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="id"
        label="#ID"
        width="50"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop=""
        label="国家/地区"
        width="150"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.country_info.name }}-{{
              scope.row.country_info.name_en
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        prop="get_member"
        label="会员信息"
        width="320"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.member.member_level === '1'"
            v-html="
              '姓名：' +
              scope.row.member.full_name +
              '<br />' +
              '手机号码：' +
              scope.row.member.mobile
            "
          ></span>
          <span
            v-if="scope.row.member.member_level === '3'"
            v-html="
              scope.row.member.full_name +
              '<br />' +
              '会员编号：' +
              scope.row.member.uname +
              '<br />' +
              '手机号码：' +
              scope.row.member.mobile +
              '<br />' +
              '身份证号：' +
              scope.row.member.identity_number
            "
          ></span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          header-align="center"
          prop="start_settle"
          label="期别"
          width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="reach_agent_name"
        label="达成成就"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="qualified_agent_name"
        label="资格成就"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="highest_agent_name"
        label="最高成就"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="status_str"
        label="状态类型"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="get_user.full_name"
        label="最近操作员"
        width="100"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.get_user !== ''"
            v-html="scope.row.get_user.full_name"
          ></span>
          <span
            v-if="scope.row.get_user === ''"
            v-html="'来源商城'"
            style="color: #cccccc"
          ></span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="start_time"
        label="开始时间"
        width="180"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="end_time"
        label="结束时间"
        width="180"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.end_time | timeStrSubOneSecond }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="created_at"
        label="创建时间"
        width="180"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        fixed="right"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            @click="editData(scope.row)"
            style="margin: 2px 0"
            type="primary"
            plain
            size="small"
            v-if="
              myfun.checkAuthRule('adminMemberEditAgentLevelLog') &&
              scope.row.can_edit_staus.can_edit_reach == '1'
            "
            >编辑</el-button
          ><br />
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        style="margin-top: 1rem"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="formData.page"
        :page-sizes="pageSizs"
        :page-size="formData.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="formData.total"
      >
      </el-pagination>
    </div>

    <!-- 编辑 -->
    <el-dialog
      title="信息修改"
      :model="editFormData"
      :visible.sync="edit_dialog"
      v-loading="loading"
    >
      <el-form class="huiyuanxinxi" ref="edit_dialog">
        <el-form-item label="会员编号" :label-width="formLabelWidth">
          <div style="text-align: left">{{ editFormData.uname }}</div>
        </el-form-item>
        <el-form-item label="会员姓名" :label-width="formLabelWidth">
          <div style="text-align: left">{{ editFormData.full_name }}</div>
        </el-form-item>
        <el-form-item label="达成成就" :label-width="formLabelWidth">
          <el-select
            style="width: 130px"
            v-model="editFormData.reach_agent_level_id"
            placeholder="请选择"
          >
            <el-option label="暂无奖衔" value="0"></el-option>
            <el-option
              :label="item.agent_name"
              :value="item.id"
              v-for="(item, index) in agentLevel_rs"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态类型" :label-width="formLabelWidth">
          <el-select
            style="width: 130px"
            v-model="editFormData.type"
            placeholder="请选择"
          >
            <el-option label="系统设置" value="1">系统设置</el-option>
            <el-option label="手动设置" value="2">手动设置</el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="edit_dialog = false">取 消</el-button>
        <el-button type="primary" @click="doEditManage()"> 确 定 </el-button>
      </div>
    </el-dialog>
    <!-- 批量维护 -->
    <el-dialog
      title="批量维护"
      :model="bitchFormData"
      :visible.sync="batch_manage"
      v-loading="loading"
    >
      <el-form class="huiyuanxinxi" ref="batch_manage">
        <el-form-item label="当前选中" :label-width="formLabelWidth">
          <el-tag
            v-for="(item, index) in multipleSelection"
            :key="index"
            class="batch_manage_tags"
            :closable="multipleSelection.length > 1"
            :disable-transitions="true"
            @close="handleClose(item)"
          >
            <span> {{ item.member.uname }},{{ item.member.full_name }} </span>
          </el-tag>
        </el-form-item>
        <el-form-item label="达成成就" :label-width="formLabelWidth">
          <el-select
            class="el-select"
            v-model="bitchFormData.reach_agent_level_id"
            placeholder="请选择"
          >
            <el-option label="暂无奖衔" value="0"></el-option>
            <el-option
              :label="item.agent_name"
              :value="item.id"
              v-for="(item, index) in agentLevel_rs"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态类型" :label-width="formLabelWidth">
          <el-select
            class="el-select"
            v-model="bitchFormData.type"
            placeholder="请选择"
          >
            <el-option label="系统设置" value="1">系统设置</el-option>
            <el-option label="手动设置" value="2">手动设置</el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="batch_manage = false">取 消</el-button>
        <el-button type="primary" @click="doBatchManage()"> 确 定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { settleData } from "@/util/settleData";
export default {
  name: "MemberNoRepeatedValid",
  components: {},
  data() {
    return {
      test: true,
      countryList: [],
      input: "",
      formData: {
        country_id: "",
        page: 1,
        pagesize: 10,
        total: 0,
        keywords: "",
        searchField: "1",
        searchType: "0",
        reach_agent_level_id: "-1",
        qualified_agent_level_id: "-1",
        highest_agent_level_id: "-1",
        start_settle_mark: "",
        end_settle_mark: "",
      },
      start_settle_options: settleData.start,
      end_settle_options: settleData.end,
      tableData: [],
      agentLevel_rs: [],
      loading: false,
      formLabelWidth: "120px",
      multipleSelection: [],
      edit_dialog: false,
      editFormData: {
        id: "0",
        uname: "",
        full_name: "",
        reach_agent_level_id: "",
        type: "",
      },
      batch_manage: false,
      bitchFormData: {
        member_id: [],
        reach_agent_level_id: "",
        type: "",
      },
    };
  },
  methods: {
    getList(page = 0) {
      this.tableData = [];
      if (page > 0) {
        this.formData.page = page;
      }
      this.loading = true;
      this.myfun.request("adminMemberGetAgentLevelLogList.api", this.formData)
        .then((result) => {
          if (result.code === "1") {
            this.tableData = result.data.list;
            this.agentLevel_rs = result.data.agentLevel_rs;
          }
          this.formData.total = parseInt(result.data.total);
          this.loading = false;
        });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.formData.pagesize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.formData.page = val;
      this.getList();
    },
    onSearch() {
      this.getList(1);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },
    handleClose(tag) {
      if (this.multipleSelection.length > 1) {
        this.$refs.dataTable.toggleRowSelection(tag, false);
      } else {
        this.$message.warning("请至少要保留一个操作项");
      }
    },
    editData(row) {
      this.editFormData.id = row.id;
      this.editFormData.uname = row.member.uname;
      this.editFormData.full_name = row.member.full_name;
      this.editFormData.reach_agent_level_id = row.reach_agent_level_id;
      this.editFormData.type = row.type;
      this.edit_dialog = true;
    },
    doEditManage() {
      this.loading = true;
      this.myfun.request("adminMemberEditAgentLevelLog.api", this.editFormData)
        .then((result) => {
          if (result.code === "1") {
            this.getList();
            this.edit_dialog = false;
          } else {
            this.loading = false;
          }
        });
    },
    statusManage() {
      this.batch_manage = 1;
    },
    doBatchManage() {
      //批量复选
      if (this.multipleSelection.length < 1) {
        this.$message.warning("请选择要修改的会员");
      } else {
        let member_id = [];
        this.multipleSelection.forEach((item, key) => {
          member_id.push(item.id);
        });
        this.bitchFormData.member_id = member_id;
        //验证通过执行请求
        this.loading = true;
        this.myfun.request("adminMemberBitchAgentLog.api", this.bitchFormData)
          .then((result) => {
            if (result.code === "1") {
              this.getList();
              this.batch_manage = 0;
            } else {
            this.loading = false;
          }
          });
      }
    },
    changeCountry() {
      this.getList();
    },
  },
  created() {
    this.loading = true;
    this.myfun.request("CommonPubGetCountryList.api").then((result) => {
        if (result.code === "1") {
          this.countryList = result.data;
          this.formData.country_id = this.countryList[0].id;
          this.getList(1);
        }
      });
  },
  computed: {
    pageSizs() {
      return this.$store.state.pageSizes;
    },
  },
};
</script>

<style>
.huiyuanxinxi .el-form-item {
  margin: 0 0 15px 0;
}
.mainwhite {
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}
.mainwhite-tiop {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
  justify-content: flex-end;
}
.el-form-item {
  margin-bottom: 0;
}
.batch_manage_tags {
  margin-right: 10px;
}
</style>
