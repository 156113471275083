<template>
  <div class="mainwhite">
    <div class="mainwhite-tiop">
      <el-form
          :inline="true"
          ref="formData"
          :model="formData"
          class="demo-form-inline"
      >
        <el-form-item
            label=""
            :label-width="formLabelWidth"
            prop="start_settle_mark"
            required
        >
          <el-cascader
              v-model="formData.start_settle_mark"
              :options="start_settle_options"
              clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item
                      label=""
                      :label-width="formLabelWidth"
                      prop="end_settle_mark"
        >
          <el-cascader
              v-model="formData.end_settle_mark"
              :options="end_settle_options"
              clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-select
              style="width: 120px"
              v-model="formData.netType"
              placeholder="请选择"
          >
            <el-option label="网络类型" value="0">网络类型</el-option>
            <el-option label="安置网业绩" value="1">安置网业绩</el-option>
            <el-option label="推荐网业绩" value="2">推荐网业绩</el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="formData.netType > 0">
          <el-input
              placeholder="代数"
              v-model="formData.layer" type="number" style="width: 100px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
              placeholder="请输入内容"
              v-model="formData.keywords"
              class="input-with-select"
          >
            <el-select
                style="width: 120px"
                v-model="formData.searchField"
                slot="prepend"
                placeholder="请选择"
            >
              <el-option label="会员编号" value="1">会员编号</el-option>
<!--              <el-option label="手机号" value="2">手机号</el-option>-->
<!--              <el-option label="身份证号" value="3">身份证号</el-option>-->
<!--              <el-option label="姓名" value="4">姓名</el-option>-->
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch('formData')"
          >查询
          </el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table
        ref="dataTable"
        v-loading="loading"
        :data="tableData"
        border
        stripe
        style="width: 100%; margin-top: 20px"
        @selection-change="handleSelectionChange"
    >
      <el-table-column
          prop="id"
          label="#ID"
          width="80">
      </el-table-column>
      <el-table-column
          prop="settle.name"
          label="期数"
          width="80">
      </el-table-column>
      <el-table-column
          prop="name"
          label="会员信息" >
        <template slot-scope="scope">
          <span v-if="scope.row.member_level === '1'" v-html="
          '真实姓名：'+scope.row.full_name+'<br />'+
          '手机号码：'+scope.row.mobile
"></span>
          <span v-if="scope.row.member_level === '3'" v-html="
          '会员编号：'+scope.row.uname+'<br />'+
          '真实姓名：'+scope.row.full_name+'<br />'+
          '手机号码：'+scope.row.mobile
"></span>
        </template>
      </el-table-column>
      <el-table-column v-if="formData.netType > 0"
          prop="layer"
          label="层" width="50"
          >
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="current_week_first_order_pv"-->
<!--          label="周首单类业绩"-->
<!--          width="135"-->
<!--          align="center"-->
<!--          header-align="center"-->
<!--      >-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          prop="current_week_repeat_pv"-->
<!--          label="周重消业绩"-->
<!--          width="135"-->
<!--          align="center"-->
<!--          header-align="center"-->
<!--      >-->
<!--      </el-table-column>-->
      <el-table-column
          prop="current_week_personal_pv"
          label="个人周业绩"
          align="center"
          width="135"
          header-align="center"
      >
      </el-table-column>
      <el-table-column
          prop="current_week_recommend_team_pv"
          label="周推荐团队业绩"
          width="135"
          align="center"
          header-align="center"
      >
      </el-table-column>
      <el-table-column
          prop="current_week_parent_team_pv"
          label="周安置团队业绩"
          width="135"
          align="center"
          header-align="center"
      >
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="current_month_repeat_pv"-->
<!--          label="月重消业绩"-->
<!--          width="135"-->
<!--          align="center"-->
<!--          header-align="center"-->
<!--      >-->
<!--      </el-table-column>-->
      <el-table-column
          prop="current_month_personal_pv"
          label="月个人业绩"
          width="135"
          align="center"
          header-align="center"
      >
      </el-table-column>
      <el-table-column
          prop="current_month_recommend_team_pv"
          label="月推荐团队业绩"
          width="135"
          align="center"
          header-align="center"
      >
      </el-table-column>
      <el-table-column
          prop="current_month_recommend_team_pv"
          label="月安置团队业绩"
          width="135"
          align="center"
          header-align="center"
      >
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="created_at"-->
<!--          label="达成成就">-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          prop="created_at"-->
<!--          label="资格奖衔">-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          prop="created_at"-->
<!--          label="最高成就">-->
<!--      </el-table-column>-->
    </el-table>
    <div class="block">
      <el-pagination
          style="margin-top: 1rem"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="formData.page"
          :page-sizes="pageSizs"
          :page-size="formData.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="formData.total"
      >
      </el-pagination>
    </div>


    <el-drawer
        title="奖金明细"
        :visible.sync="showLog"
        direction="ltr"
        size="60%"
    >
      <el-table
          v-loading="loading"
          :data="bonusLogData"
          border
          stripe
          style="width: 100%; margin-top: 20px"
      >
        <el-table-column
            prop="id"
            label="#ID" width="100"
        >
        </el-table-column>
        <el-table-column
            prop="bonusType"
            label="奖金类型" width="100"
        >
        </el-table-column>
        <el-table-column
            prop="payable_bonus"
            label="应发" width="100">
        </el-table-column>
        <el-table-column
            prop="paid_bonus"
            label="实发" width="100">
        </el-table-column>
        <el-table-column prop="freezeStatusStr" label="保留状态" width="100">
        </el-table-column>
        <el-table-column prop="restrictedAwardsStr" label="冻结状态" width="100">
        </el-table-column>
        <el-table-column
            prop="created_at"
            label="结算时间" width="200"
        >
        </el-table-column>
        <el-table-column
            prop="order.ordersn"
            label="所属订单" width="100">
        </el-table-column>
      </el-table>
    </el-drawer>
  </div>
</template>

<script>
  import { settleData } from "@/util/settleData";
  export default {
    name: 'MemberPvSearch',
    components: {},
    data() {
      return {
        showLog: false,
        formData: {
          page: 1,
          pagesize: 10,
          total: 0,
          keywords: '',
          searchField: '1',
          settleId: '',
          netType: '0',
          layer: '',
          start_settle_mark: "",
          end_settle_mark: "",
        },
        tableData: [],
        bonusLogData:[],
        loading: false,
        formLabelWidth: "120px",
        start_settle_options: settleData.start,
        end_settle_options: settleData.end,
      };
    },
    methods: {
      getList(page = 0) {
        this.tableData = [];
        if (page > 0) {
          this.formData.page = page;
        }
        this.loading = true;
        this.myfun.request('adminSettleGetMemberPvList.api', this.formData, 'post').then((result) => {
          if (result.code === '1') {
            this.tableData = result.data.list;
          }
          this.formData.total = parseInt(result.data.total);
          this.loading = false;
        });
      },
      getSettleBonusLog(id) {
        this.loading = true;
        this.myfun.request('adminSettleGetSettleBonusLog.api', {id:id}, 'post').then((result) => {
          if (result.code === '1') {
            this.bonusLogData = result.data;
            this.showLog = true;
          }
          this.loading = false;
        });
      },
      handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
        this.formData.pagesize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
        this.formData.page = val;
        this.getList();
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      handleClose(tag) {
        if (this.multipleSelection.length > 1) {
          this.$refs.dataTable.toggleRowSelection(tag, false);
        } else {
          this.$message.warning("请至少要保留一个操作项");
        }
      },
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //验证通过执行请求
            this.loading = true;
            let apiName =
              this.editNetformData.type === 1
                ? "adminMemberEditMemberRecommend.api"
                : "adminMemberEditMemberParent.api";
            this.myfun.request(apiName, this.editNetformData)
              .then((result) => {
                if (result.code === "1") {
                  this.getList();
                  this.editNetdialogFormVisible = false;
                }
                this.loading = false;
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      onSearch() {
        this.getList(1);
      },
    },
    created() {
      // this.getList(1);
    },
    mounted() {
    },
    computed: {
      pageSizs() {
        return this.$store.state.pageSizes;
      },
    },
  };
</script>

<style>
  .huiyuanxinxi .el-form-item {
    margin: 0 0 15px 0;
  }

  .switshsdf {
    display: flex;
    flex-direction: column;
  }

  .mainwhite {
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
  }

  .mainwhite-tiop {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #f5f5f5;
    justify-content: flex-end;
  }

  .el-form-item {
    margin-bottom: 0;
  }
</style>
