<template>
  <div class="mainwhite">
    <div class="mainwhite-tiop">
      <el-form
        :inline="true"
        ref="formData"
        :model="formData"
        class="demo-form-inline"
      >
        <el-form-item>
          <el-button @click="myfun.myRouterAutoPush('/settle/settle-list')"
            >返回结算周期
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="请输入内容"
            v-model="formData.keywords"
            class="input-with-select"
          >
            <el-select
              style="width: 120px"
              v-model="formData.searchField"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="会员编号" value="1"></el-option>
              <el-option label="手机号" value="2"></el-option>
<!--              <el-option label="身份证号" value="3"></el-option>-->
<!--              <el-option label="姓名" value="4"></el-option>-->
              <el-option label="推荐人编号" value="5"></el-option>
              <el-option label="安置人编号" value="6"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch('formData')"
            >查询
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      ref="dataTable"
      v-loading="loading"
      :data="tableData"
      border
      stripe
      style="width: 100%; margin-top: 20px"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        prop="id"
        label="#ID"
        width="100"
        align="center"
        header-align="center"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        label="期数"
        align="center"
        width="100"
        header-align="center"
      >
      </el-table-column>
      <el-table-column prop="name" label="会员信息" header-align="center" width="200">
        <template slot-scope="scope">
          <span
            v-if="scope.row.member_level === '1'"
            v-html="
              '真实姓名：' +
              scope.row.full_name +
              '<br />' +
              '手机号码：' +
              scope.row.mobile
            "
          ></span>
          <span
            v-if="scope.row.member_level === '3'"
            v-html="
              scope.row.full_name +
              '<br />' +
              '会员编号：' +
              scope.row.uname +
              '<br />' +
              '手机号码：' +
              scope.row.mobile +
              '<br />' +
              '身份证号：' +
              scope.row.identity_number
            "
          ></span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="推荐人" header-align="center" width="200">
        <template slot-scope="scope">
          <span
            v-if="scope.row.recommend_info.member_level === '1'"
            v-html="
              '真实姓名：' +
              scope.row.recommend_info.full_name +
              '<br />' +
              '手机号码：' +
              scope.row.recommend_info.mobile
            "
          ></span>
          <span
            v-if="scope.row.recommend_info.member_level === '3'"
            v-html="
              scope.row.recommend_info.full_name +
              '<br />' +
              '会员编号：' +
              scope.row.recommend_info.uname +
              '<br />' +
              '手机号码：' +
              scope.row.recommend_info.mobile +
              '<br />' +
              '身份证号：' +
              scope.row.recommend_info.identity_number
            "
          ></span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="安置人" header-align="center" width="200">
        <template slot-scope="scope">
          <span
            v-if="scope.row.parent_info.member_level === '1'"
            v-html="
              '真实姓名：' +
              scope.row.parent_info.full_name +
              '<br />' +
              '手机号码：' +
              scope.row.parent_info.mobile
            "
          ></span>
          <span
            v-if="scope.row.parent_info.member_level === '3'"
            v-html="
              scope.row.parent_info.full_name +
              '<br />' +
              '会员编号：' +
              scope.row.parent_info.uname +
              '<br />' +
              '手机号码：' +
              scope.row.parent_info.mobile +
              '<br />' +
              '身份证号：' +
              scope.row.parent_info.identity_number
            "
          ></span>
        </template>
      </el-table-column>
<!--      <el-table-column-->
<!--        prop="current_week_first_order_pv"-->
<!--        label="周首单类业绩"-->
<!--        width="120"-->
<!--        align="center"-->
<!--        header-align="center"-->
<!--      >-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--        prop="current_week_repeat_pv"-->
<!--        label="周重消业绩"-->
<!--        width="120"-->
<!--        align="center"-->
<!--        header-align="center"-->
<!--      >-->
<!--      </el-table-column>-->
      <el-table-column
        prop="my_week_pv"
        label="个人本期新增业绩"
        align="center"
        header-align="center"
      >
      </el-table-column>
<!--      <el-table-column-->
<!--        prop="current_month_recommend_team_pv"-->
<!--        label="个人本期"-->
<!--        width="120"-->
<!--        align="center"-->
<!--        header-align="center"-->
<!--      >-->
<!--      </el-table-column>-->
      <el-table-column
        prop="last_settle_region_pv_balance"
        label="上级上期剩余业绩"
        align="center"
        header-align="center"
      >
      </el-table-column>
      <el-table-column
        prop="region_week_add_pv"
        label="上级本期新增网络业绩"
        align="center"
        header-align="center"
      >
      </el-table-column>
<!--      <el-table-column-->
<!--        prop="current_month_repeat_pv"-->
<!--        label="月重消业绩"-->
<!--        width="120"-->
<!--        align="center"-->
<!--        header-align="center"-->
<!--      >-->
<!--      </el-table-column>-->
      <el-table-column
        prop="region_week_total_pv"
        label="上级本期实际业绩"
        align="center"
        header-align="center"
      >
      </el-table-column>
      <el-table-column
        prop="region_pv_balance"
        label="上级本期剩余业绩"
        align="center"
        header-align="center"
      >
      </el-table-column>
      <!--      <el-table-column-->
      <!--          prop="created_at"-->
      <!--          label="达成成就">-->
      <!--      </el-table-column>-->
      <!--      <el-table-column-->
      <!--          prop="created_at"-->
      <!--          label="资格奖衔">-->
      <!--      </el-table-column>-->
      <!--      <el-table-column-->
      <!--          prop="created_at"-->
      <!--          label="最高成就">-->
      <!--      </el-table-column>-->
    </el-table>
    <div class="block">
      <el-pagination
        style="margin-top: 1rem"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="formData.page"
        :page-sizes="pageSizs"
        :page-size="formData.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="formData.total"
      >
      </el-pagination>
    </div>

    <el-drawer
      title="奖金明细"
      :visible.sync="showLog"
      direction="ltr"
      size="60%"
    >
      <el-table
        v-loading="loading"
        :data="bonusLogData"
        border
        stripe
        style="width: 100%; margin-top: 20px"
      >
        <el-table-column prop="id" label="#ID" width="100"> </el-table-column>
        <el-table-column prop="bonusType" label="奖金类型" width="100">
        </el-table-column>
        <el-table-column prop="payable_bonus" label="应发" width="100">
        </el-table-column>
        <el-table-column prop="paid_bonus" label="实发" width="100">
        </el-table-column>
        <el-table-column prop="freezeStatusStr" label="保留状态" width="100">
        </el-table-column>
        <el-table-column prop="restrictedAwardsStr" label="冻结状态" width="100">
        </el-table-column>
        <el-table-column prop="created_at" label="结算时间" width="200">
        </el-table-column>
        <el-table-column prop="order.ordersn" label="所属订单" width="100">
        </el-table-column>
      </el-table>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "SettlePvList",
  components: {},
  data() {
    return {
      showLog: false,
      formData: {
        page: 1,
        pagesize: 10,
        total: 0,
        keywords: "",
        searchField: "1",
        settleId: "",
      },
      tableData: [],
      bonusLogData: [],
      loading: false,
      formLabelWidth: "120px",
    };
  },
  methods: {
    getList(page = 0) {
      this.tableData = [];
      if (page > 0) {
        this.formData.page = page;
      }
      this.loading = true;
      this.myfun.request("adminSettleGetSettlePvList.api", this.formData)
        .then((result) => {
          if (result.code === "1") {
            this.tableData = result.data.list;
          }
          this.formData.total = parseInt(result.data.total);
          this.loading = false;
        });
    },
    getSettleBonusLog(id) {
      this.loading = true;
      this.myfun.request("adminSettleGetSettleBonusLog.api", { id: id })
        .then((result) => {
          if (result.code === "1") {
            this.bonusLogData = result.data;
            this.showLog = true;
          }
          this.loading = false;
        });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.formData.pagesize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.formData.page = val;
      this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleClose(tag) {
      if (this.multipleSelection.length > 1) {
        this.$refs.dataTable.toggleRowSelection(tag, false);
      } else {
        this.$message.warning("请至少要保留一个操作项");
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //验证通过执行请求
          this.loading = true;
          let apiName =
            this.editNetformData.type === 1
              ? "adminMemberEditMemberRecommend.api"
              : "adminMemberEditMemberParent.api";
          this.myfun.request(apiName, this.editNetformData)
            .then((result) => {
              if (result.code === "1") {
                  this.getList();
                  this.editNetdialogFormVisible = false;
                }
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onSearch() {
      this.getList(1);
    },
  },
  created() {
    if (typeof this.$route.query.id !== "undefined") {
      this.formData.settleId = this.$route.query.id;
    }
    this.getList()
  },
  mounted() {},
  computed: {
    pageSizs() {
      return this.$store.state.pageSizes;
    },
  },
};
</script>

<style>
.huiyuanxinxi .el-form-item {
  margin: 0 0 15px 0;
}

.switshsdf {
  display: flex;
  flex-direction: column;
}

.mainwhite {
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}
.mainwhite-tiop {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
  justify-content: flex-end;
}
.el-form-item {
  margin-bottom: 0;
}
</style>
